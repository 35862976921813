.team-standings-page {
  margin-top: 2vmin;
}

.team-detail-table a {
  text-decoration: none;
  color: black;
  font-weight: 700;
  width: 100%;
}

.team-image {
  max-height: 35vmin;
}

.team-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.team-players {
  display: flex;
  justify-content: center;
  padding: 1vmin;
  margin: 1vmin;
}
