.document-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.document-page h2 {
  font-weight: bold;
}

.docu-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  margin: 2vmin;
}

.docu-container a {
  text-decoration: none;
  color: white;
}

.docu-card {
  flex-direction: column;
  padding: 3vmin;
  margin: 1.5vmin;
  height: auto;
  width: 45vmin;
  box-shadow: 1vmin 1vmin black;
}

.docu-card img {
  height: 20vmin;
}

#doc-body {
  display: flex;
  flex-direction: column;
}

#doc-body button {
  margin-top: 2vmin;
}

@media (max-width: 768px) {
  .docu-container {
    grid-template-columns: 1fr;
  }
  .docu-card {
    height: auto;
  }

  .docu-card img {
    height: 30vmin;
  }
}
