.player-standings-page {
  margin-top: 2vmin;
}

@media screen and (max-width: 767px) {
  .table {
    font-size: 0.8em;
  }

  .table thead th {
    white-space: nowrap;
  }

  .table tbody td {
    white-space: nowrap;
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
}
