.team-standings-page {
  margin-top: 2vmin;
}

.team-standings-table a {
  width: 100%;
  color: black;
}

/* Responsive table styles */
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media screen and (max-width: 767px) {
  .table {
    font-size: 0.8em;
  }

  .table thead th {
    white-space: nowrap;
  }

  .table tbody td {
    white-space: nowrap;
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
}
