.home-bottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
  align-items: center;
  margin: 3vmin 0px 3vmin 0px;
  height: 100%;
}

.fall-results {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 2vmin;
}

.info-map img {
  height: 35vmin;
  border: 2px solid black;
}

.home-second-row {
  height: 100%;
  background-color: pink;
}

#fall-card {
  padding: 1vmin;
  margin: 0.5vmin;
}

.home-news {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 3vmin;
}

#home-news-card {
  width: 70vmin;
  height: 30vmin;
  overflow: scroll;
  margin: 1vmin;
}

#home-news-card h2 {
  font-weight: bold;
}

#upcomingEvents {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

#upcomingEvents img {
  width: 30%;
  margin: 1%;
}

#fall-card {
  box-shadow: 0.75vmin 0.5vmin black;
}

@media (max-width: 768px) {
  .main-homepage {
    display: flex;
    flex-direction: column;
  }

  .home-bottom,
  .fall-results,
  .home-news {
    flex-direction: column;
  }

  .info-map img {
    height: 60vmin;
  }

  #home-news-card {
    width: 90vmin;
  }

  #upcomingEvents img {
    width: 90%;
    margin: 5%;
  }
}
